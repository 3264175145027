import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import _ from "lodash";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Http from '../../../services/Http';
import apiPath from '../../../constants/apiPath';
import { adminLogin } from "../../../actions/index";
import { connect } from "react-redux";
import validator from "validator";
import helper from "../../../constants/helper";
import logo from '../../../assets/img/brand/login_logo.png';

var defLang = "";
defLang = helper.getLanguageFromCookie('googtrans');

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    // If you want to use the reset state function, you need to have a reference to the ValidationForm component
    //If your React < 16.3, check https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      email: '',
      isMailSent: false
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.sendForgotPassLink(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {

  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  async sendForgotPassLink(postData) {
    let path = apiPath.forgotPassword;

    if (!_.isEmpty(postData)) {
      this.setState({ loading: true });
      let options = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
      }
      let result = await Http(path, options);
      this.setState({ loading: false });
      if (result.ok) {
        if (result.status === 200) {

          let resultData = await result.json();
          if (resultData.success) {

            this.setState({ submitFormSuccess: resultData.msg, email: '', submitFormError: null });
            setTimeout(() => {
              this.setState({ submitFormSuccess: null });
            }, 5000);
            this.resetForm();
          } else {
            this.setState({ submitFormError: resultData.msg, submitFormSuccess: null });
            setTimeout(() => {
              this.setState({ submitFormError: null });
            }, 5000);
          }
        }
      } else {
        this.setState({ submitFormError: result.statusText, submitFormSuccess: null });
        setTimeout(() => {
          this.setState({ submitFormError: null });
        }, 5000);
      }
    }
  }

  responseError() {
    if (this.state.submitFormError) {
      return <Alert color="danger">
        {this.state.submitFormError}
      </Alert>
    } else {
      return null;
    }
  }

  responseSuccess() {
    if (this.state.submitFormSuccess) {
      return <Alert color="success">
        {this.state.submitFormSuccess}
      </Alert>
    } else {
      return null;
    }
  }

  showLoading() {
    if (this.state.loading) {
      return <i className="fa fa-spinner fa-spin"></i>
    }
  }

  render() {

    if (!_.isEmpty(this.props.admin)) {
      return <Redirect to='/dashboard' />
    }

    return (
      <div className="app flex-row align-items-center login-body">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  {this.responseError()} {this.responseSuccess()}
                  <CardBody>
                    <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                      ref={this.formRef}
                      immediate={this.state.immediate}
                      setFocusOnError={this.state.setFocusOnError}
                      defaultErrorMessage={{ required: "Please enter something." }} >
                      <h1>Forgot Password</h1>
                      <p className="text-muted">Enter your email address </p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <TextInput name="email" id="email" type="email"
                          validator={validator.isEmail}
                          errorMessage={{ validator: "Please enter a valid email" }}
                          value={this.state.email}
                          onChange={this.handleChange}
                          placeholder={"Email"}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button disabled={this.state.loading} color="primary" type={"submit"} className="px-4">
                            <span className="notranslate">{(defLang == "/en/ru") ? "Искать" : ((defLang == "/en/tr") ? "Gönder" : "Submit")} </span>
                            {this.showLoading()}
                          </Button>
                        </Col>
                        <Col xs="6">
                          {/* <Button tag={Link} to={"login"} color="link" className="px-0">Back to Login</Button> */}
                          <Link to="/login">Back to Login</Link>
                        </Col>
                      </Row>
                    </ValidationForm>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none forgotPP" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                     
                      <img src={logo} alt="Speedy Tum Tum"/>
                      <h2 className="notranslate">Speedy Tum Tum</h2>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
