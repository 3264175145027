import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import('./views/Profile'),
  loading: Loading,
});

const Reviews = Loadable({
  loader: () => import('./views/Reviews'),
  loading: Loading,
});

const Orders = Loadable({
  loader: () => import('./views/Orders/Orders'),
  loading: Loading,
});

const ViewOrder = Loadable({
  loader: () => import('./views/Orders/ViewOrder'),
  loading: Loading,
});

const TableReservations = Loadable({
  loader: () => import('./views/TableReservations/TableReservations'),
  loading: Loading,
});

const ViewTableReservation = Loadable({
  loader: () => import('./views/TableReservations/ViewTableReservation'),
  loading: Loading,
});

const DeliveryBoys = Loadable({
  loader: () => import('./views/DeliveryBoys/DeliveryBoys'),
  loading: Loading,
});
const AddDeliveryBoy = Loadable({
  loader: () => import('./views/DeliveryBoys/AddDeliveryBoy'),
  loading: Loading,
});
const EditDeliveryBoy = Loadable({
  loader: () => import('./views/DeliveryBoys/EditDeliveryBoy'),
  loading: Loading,
});
const ViewDeliveryBoy = Loadable({
  loader: () => import('./views/DeliveryBoys/ViewDeliveryBoy'),
  loading: Loading,
});

const Notifications = Loadable({
  loader: () => import('./views/Notifications'),
  loading: Loading,
});

const Materials = Loadable({
  loader: () => import('./views/RestaurantMenus/Materials'),
  loading: Loading,
});
const AddMaterial = Loadable({
  loader: () => import('./views/RestaurantMenus/AddMaterial'),
  loading: Loading,
});
const EditMaterial = Loadable({
  loader: () => import('./views/RestaurantMenus/EditMaterial'),
  loading: Loading,
});

const RestaurantMenus = Loadable({
  loader: () => import('./views/RestaurantMenus/RestaurantMenus'),
  loading: Loading,
});
const AddRestaurantMenu = Loadable({
  loader: () => import('./views/RestaurantMenus/AddRestaurantMenu'),
  loading: Loading,
});
const EditRestaurantMenu = Loadable({
  loader: () => import('./views/RestaurantMenus/EditRestaurantMenu'),
  loading: Loading,
});

const Cuisines = Loadable({
  loader: () => import('./views/Cuisines/Cuisines'),
  loading: Loading,
});
const AddCuisine = Loadable({
  loader: () => import('./views/Cuisines/AddCuisine'),
  loading: Loading,
});
const EditCuisine = Loadable({
  loader: () => import('./views/Cuisines/EditCuisine'),
  loading: Loading,
});

const Offers = Loadable({
  loader: () => import('./views/Offers/Offers'),
  loading: Loading,
});
const AddOffer = Loadable({
  loader: () => import('./views/Offers/AddOffer'),
  loading: Loading,
});
const EditOffer = Loadable({
  loader: () => import('./views/Offers/EditOffer'),
  loading: Loading,
});
const ViewOffer = Loadable({
  loader: () => import('./views/Offers/ViewOffer'),
  loading: Loading,
});
const Earnings = Loadable({
  loader: () => import('./views/Earnings/Earnings'),
  loading: Loading,
});
const Invoices = Loadable({
  loader: () => import('./views/Invoices/Invoices'),
  loading: Loading,
});

const Subscriptions = Loadable({
  loader: () => import('./views/Subscriptions/Subscriptions'),
  loading: Loading,
});

const newOrder = Loadable({
  loader: () => import('./views/Orders/NewOrder'),
  loading: Loading,
});




// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/profile', name: 'Profile', component: Profile },
  { path: '/reviews', name: 'Reviews', component: Reviews },
  
  { path: '/orders', exact: true, name: 'Orders', component: Orders },
  { path: '/orders/view', exact: true, name: 'View Order', component: ViewOrder },

  { path: '/table-reservations', exact: true, name: 'Table Reservations', component: TableReservations },
  { path: '/table-reservations/view', exact: true, name: 'View Table Reservation', component: ViewTableReservation },
  
  { path: '/delivery-boys', exact: true, name: 'Delivery Boys', component: DeliveryBoys },
  { path: '/delivery-boys/add', exact: true, name: 'Add Delivery Boy', component: AddDeliveryBoy },
  { path: '/delivery-boys/edit', exact: true, name: 'Edit Delivery Boy', component: EditDeliveryBoy },
  { path: '/delivery-boys/view', exact: true, name: 'View Delivery Boy', component: ViewDeliveryBoy },
  
  { path: '/cuisines', exact: true, name: 'Cuisines', component: Cuisines },
  { path: '/cuisines/add', exact: true, name: 'Add Cuisine', component: AddCuisine },
  { path: '/cuisines/edit', exact: true, name: 'Edit Cuisine', component: EditCuisine },

  { path: '/notifications', exact: true, name: 'Notifications List', component: Notifications },

  { path: '/menus/materials', exact: true, name: 'Materials List', component: Materials },
  { path: '/menus/add-material', exact: true, name: 'Add Material', component: AddMaterial },
  { path: '/menus/edit-material', exact: true, name: 'Edit Material', component: EditMaterial },

  { path: '/menus/restaurant-menus', exact: true, name: 'Menus List', component: RestaurantMenus },
  { path: '/menus/add-menu', exact: true, name: 'Add Menu', component: AddRestaurantMenu },
  { path: '/menus/edit-menu', exact: true, name: 'Edit Menu', component: EditRestaurantMenu },

  { path: '/offers', exact: true, name: 'Offer List', component: Offers },
  { path: '/offers/add', exact: true, name: 'Add Offer', component: AddOffer },
  { path: '/offers/edit', exact: true, name: 'Edit Offer', component: EditOffer },
  { path: '/offers/view', exact: true, name: 'View Offer', component: ViewOffer },

  { path: '/transactions', exact: true, name: 'Revenue List', component: Earnings },
  { path: '/invoices', exact: true, name: 'Payment Invoice List', component: Invoices },

  { path: '/subscriptions', exact: true, name: 'Subscription List', component: Subscriptions },

  { path: '/neworder', exact: true, name: 'newOrder', component: newOrder },
  

];

export default routes;
