import React, { Component } from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500 } from './views/Pages';
import Signup from './views/Pages/Login/Signup';
import ForgotPassword from './views/Pages/Login/ForgotPassword';

import ChangePassword from './views/Pages/Login/ChangePassword';
import UserChangePassword from './views/Pages/Login/UserChangePassword';

class App extends Component {
  render() {
    return (
      <>
        <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Admin" component={Login} />
            <Route exact path="/signup" name="Signup Admin" component={Signup} />
            <Route exact path="/forgot-password" name="Forgot Password" component={ForgotPassword} />
            <Route exact path="/account" name="Password Reset Page" component={ChangePassword} />
            <Route exact path="/user-account" name="Password Reset Page" component={UserChangePassword} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
        <ToastContainer />
      </>
    );
  }
}

export default App;
