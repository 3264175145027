import React, { Component } from 'react';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from "lodash";
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/logo.png'
import PubNubReact from 'pubnub-react';
import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";
import Support from "./Support";
import soundfile from '../../assets/img/restaurantOrder.mp3';
import helper from "../../constants/helper";


const audio = new Audio(soundfile);

// const pubnubPublishKey = "pub-c-8500ce84-a011-4105-bffa-6eb61347ca27";
// const pubnubSubscribeKey=  "sub-c-7fa024c2-f621-11e9-be22-ea7c5aada356";

const pubnubPublishKey = "pub-c-9fa67acd-2a4e-489e-b1b3-c1e267213b8c";
const pubnubSubscribeKey=  "sub-c-b6a5e8de-c110-11ea-a44f-6e05387a1df4";

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {

  constructor(props) {
    super(props);
    this.pubnub = new PubNubReact({
      publishKey: pubnubPublishKey,
      subscribeKey: pubnubSubscribeKey
    });
    this.state = {
      unreadNoti: 0,
      active : 'dashboard'
    }
    this.pubnub.init(this);
    this.onLogoutHandle = this.onLogoutHandle.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentWillMount() {
    this.pubnub.subscribe({
      channels: [this.props.admin._id],
      withPresence: true
    });

    this.pubnub.getMessage(this.props.admin._id, (resP) => {
      const location = window.location.hash;
      document.getElementById("manish").click(audio.play())
      this.setState({alertSound:true});
      if (!_.isEmpty(resP) && location !== '#/messages') {
        this.setState({ unreadNoti: (this.state.unreadNoti + 1) })
      }
      if (!_.isEmpty(resP) && location === '#/messages') {
        this.setState({ unreadNoti: 0 })
      }
    });
  }

  componentWillUnmount() {
      this.pubnub.unsubscribe({
          channels: [this.props.admin._id]
      });
  }

  onLogoutHandle() {
    if (!window.confirm("Are you sure to logout?")) {
      return false;
    } else {
      localStorage.removeItem("admin");
      localStorage.setItem("redirect", "/login");
      this.props.adminLogin({});
    }
  }
  
  // closePopup() {
  //   console.log(this.findDOMNode(this).parentNode.getAttribute("id"));
  // }
  closePopup = event => {
    // console.log(event.currentTarget);
    var cT = event.currentTarget;
    cT.parentNode.remove();
  }

  _handleClick(menuItem) { 
    this.setState({ active: menuItem });
  }

  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    const messages = this.pubnub.getMessage(this.props.admin._id);
    // console.log(messages);
    
    return (
      <div className="container-fluid">
        <div style={{position:'absolute', right:'5px', bottom:'45px', zIndex:'1000'}}>
            {messages.map((m, index) => <p className={"nidhi"} style={{position:'relative', boxShadow: '0px 6px 24px 0px rgba(0,0,0,0.2)', backgroundColor: '#ff8117', border: '0 solid #ff8117', padding:'15px', borderRadius:'5px'}} key={'message' + index}><span style={{position: 'absolute', right: '5px', top: '0', color: '#fff', cursor:'pointer'}} onClick={this.closePopup}>x</span><span className="notranslate">{m.message.message}</span></p>)}
            <button id="manish" style={{"visibility":"hidden"}}>.</button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <nav className="navbar navbar-expand-lg navbar-light">
              <a className="navbar-brand" href="#">
                <img src={logo} alt="" />
              </a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
              </button>
              <div className="menus">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto">
                    <li className={"nav-item " + (this.state.active == "dashboard" ? 'active' : '')}>
                      <a className={"nav-link " + (this.state.active == "dashboard" ? 'active' : '')} href="#" onClick={this._handleClick.bind(this, 'dashboard')}>
                       Dashboard
                      </a>
                    </li>

                    
                    <li className={"nav-item " + (this.state.active == "orders" ? 'active' : '')}>
                      <Link className={"nav-link " + (this.state.active == "orders" ? 'active' : '')} to="/neworder" onClick={this._handleClick.bind(this, 'neworder')}>Make Order</Link>
                    </li>

                    <li className={"nav-item " + (this.state.active == "orders" ? 'active' : '')}>
                      <Link className={"nav-link " + (this.state.active == "orders" ? 'active' : '')} to="/orders" onClick={this._handleClick.bind(this, 'orders')}>Manage Orders</Link>
                    </li>
                    <li className={"nav-item " + (this.state.active == "menus" ? 'active' : '')}>
                      <Link className={"nav-link " + (this.state.active == "menus" ? 'active' : '')} to="/menus/restaurant-menus" onClick={this._handleClick.bind(this, 'menus')}>
                        Menu Items
                      </Link>
                    </li>
                    <li className={"nav-item " + (this.state.active == "transactions" ? 'active' : '')}>
                      <Link className={"nav-link " + (this.state.active == "transactions" ? 'active' : '')} to="/transactions" onClick={this._handleClick.bind(this, 'transactions')}>Transactions</Link>
                    </li>
                    <li className={"nav-item " + (this.state.active == "invoices" ? 'active' : '')}>
                      <Link className={"nav-link " + (this.state.active == "invoices" ? 'active' : '')} to="/invoices" onClick={this._handleClick.bind(this, 'invoices')}>Payment Invoice</Link>
                    </li>
                    <li className={"nav-item " + (this.state.active == "profile" ? 'active' : '')}>
                      <Link className={"nav-link " + (this.state.active == "profile" ? 'active' : '')} to="/profile" onClick={this._handleClick.bind(this, 'profile')}>My Account</Link>
                    </li>
                    <li className="nav-item">
                      <DropdownItem tag={Link} to={"logout"} onClick={this.onLogoutHandle}><i className="fa fa-lock"></i>Logout</DropdownItem>
                    </li>
                    
                  </ul>
                </div>
               
              </div>
            </nav>
          </div>
        </div>
        <Support />
      </div>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);
