import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip, Button, Card, CardBody, CardFooter, Col, Container, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import apiPath from "../../../constants/apiPath";
import _ from "lodash";
import Http from "../../../services/Http";
import validator from "validator";
import { AlertList } from "react-bs-notifier";
import helper from '../../../constants/helper';
import { tr } from 'date-fns/locale';

class LSPRegister extends Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: true,
      position: "top",
      alerts: [],
      timeout: 5000,
      message: "",
      company_name: '',
      email: '',
      address: '',
      phone: '',
      password: '',
      confirmPassword: ''
    }

  }

  generate(type) {

    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;
    const idx = alerts.indexOf(alert);
    if (idx >= 0) {
      this.setState({
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }
  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle=""
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.registerLSP(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {

  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
    this.setState({
      company_name: '',
      email: '',
      address: '',
      phone: '',
      password: ''
    });
  }

  async registerLSP(postData) {
    let path = apiPath.registerLSP;

    if (!_.isEmpty(postData)) {
      this.setState({ loading: true });
      let options = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
      }
      let result = await Http(path, options);
      this.setState({ loading: false });
      if (result.ok) {
        if (result.status === 200) {
          let resultData = await result.json();
          if (resultData.success) {
            this.setState({ message: resultData.msg });
            this.generate('success');
            this.resetForm();
          } else {
            this.setState({ message: resultData.msg });
            this.generate('danger');
          }
        }
      } else {
        this.setState({ message: result.statusText });
        this.generate('danger');
      }
    }
  }

  showLoading() {
    if (this.state.loading) {
      return <i className="fa fa-spinner fa-spin"></i>
    }
  }

  render() {
    return (
      <div className="app flex-row align-items-center login-body">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                    ref={this.formRef}
                    immediate={this.state.immediate}
                    setFocusOnError={this.state.setFocusOnError}
                    defaultErrorMessage={{ required: "Please enter something." }} >
                    <h1>LSP - Register</h1>
                    <p className="text-muted">Create your account</p>
                    <AlertList alerts={this.state.alerts} />
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <TextInput
                        name="company_name" id="company_name" placeholder={"Company Name"}
                        required
                        value={this.state.company_name}
                        onChange={this.handleChange}
                        errorMessage="Please enter company name" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>@</InputGroupText>
                      </InputGroupAddon>
                      <TextInput name="email" id="email" type="email"
                        validator={validator.isEmail}
                        errorMessage={{ validator: "Please enter a valid email" }}
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder={"Email"}
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-location-pin"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <TextInput value={this.state.address} onChange={this.handleChange} name="address" id="address" placeholder={"Address"} required errorMessage="Please enter address" />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-phone"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <TextInput name="phone" id="phone" type="numeric"
                        validator={(value) => validator.isMobilePhone(value, 'any', { strictMode: true })}
                        errorMessage={{ validator: "Please enter a valid phone with country code" }}
                        value={this.state.phone}
                        onChange={this.handleChange}
                        placeholder={"Phone"}
                      />
                      <UncontrolledTooltip placement="left" target="phone">
                        Enter only USA phone with +1 code
                      </UncontrolledTooltip>
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <TextInput type={"password"} value={this.state.password} onChange={this.handleChange} name="password" id="password" placeholder={"Password"}
                        validator={(value) => validator.isLength(validator.trim(value), { min:6,max:10})}
                        pattern="^(?=.*\d)(?=.*[a-zA-Z]).{6,10}$"
                        errorMessage={{ pattern: "Password must be 6 to 10 characters long and should include letters and numbers." }}
                      />
                    </InputGroup>

                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <TextInput disabled={_.isEmpty(this.state.password)} type={"password"} value={this.state.confirmPassword} onChange={this.handleChange} name="confirmPassword" id="confirmPassword" placeholder={"Confirm Password"}
                        validator={(value) => {
                          if(validator.equals(value, this.state.password) && validator.isLength(validator.trim(value), { min: 1, max:10})){
                            return true;
                          }
                        }}
                        errorMessage={{ validator: 'Confirm password didn\'t match with the password.' }}
                      />
                    </InputGroup>
                    <Button color="success" block type="submit">Create Account {this.showLoading()}</Button>
                  </ValidationForm>
                </CardBody>
                <CardFooter className="p-4">
                  <Button tag={Link} color="link" block to={"lsp-login"}>Back to LSP Login</Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default LSPRegister;
