import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row} from 'reactstrap';
import { ValidationForm,SelectGroup, TextInput} from "react-bootstrap4-form-validation";
import _ from "lodash";
import {Redirect} from 'react-router-dom';
import validator from "validator";
import {Link} from 'react-router-dom';
import Http from '../../../services/Http';
import apiPath from '../../../constants/apiPath';
import { adminLogin } from "../../../actions/index";
import { connect } from "react-redux";

import logo from '../../../assets/img/brand/login_logo.png';
import helper from "../../../constants/helper";

var defLang = "";
defLang = helper.getLanguageFromCookie('googtrans');

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class Signup extends Component {
  constructor(props){
    super(props);
    // If you want to use the reset state function, you need to have a reference to the ValidationForm component
    //If your React < 16.3, check https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      immediate:true,
      setFocusOnError:true,
      clearInputOnReset:false,
      account_type: 'restaurant',
      contact_name: '',
      faclass:"fa-eye",
      passwordType:"password",
      faCclass:"fa-eye",
      passwordCType:"password",
      name: '',
      phone: '',
      country: '',
      email: '',
      email: '',
      countries: [],
      password: '',
      confirm_password: '',
      redirectTo : false
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.signup(formData);
  };

  handleButtonChange = (e) => {
    if(this.state.faclass == 'fa-eye'){
      this.setState({faclass : 'fa-eye-slash',passwordType : 'text'})
    }else{
      this.setState({faclass : 'fa-eye',passwordType : 'password'})
    }
  };

  handleCButtonChange = (e) => {
    if(this.state.faCclass == 'fa-eye'){
      this.setState({faCclass : 'fa-eye-slash',passwordCType : 'text'})
    }else{
      this.setState({faCclass : 'fa-eye',passwordCType : 'password'})
    }
  };

  handleErrorSubmit = (e,formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  };

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  };

  async signup(postData){
    let path = apiPath.signup;

    if(!_.isEmpty(postData)) {
      this.setState({loading: true});
      postData.email = postData.email.toLowerCase();       
      let options = {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        body: JSON.stringify(postData)
      }
      let result = await Http(path, options);
      this.setState({loading: false});
      if(result.ok) {
        if(result.status === 200) {
          let resultData = await result.json();

          if(resultData.success) {
            this.setState({ submitFormSuccess: resultData.msg, email: '', submitFormError: null });
            setTimeout(() => {
              this.setState({ submitFormSuccess: null });
            }, 5000);
            this.resetForm();
          } else {
            this.setState({submitFormError: resultData.msg});
            setTimeout(() => {
              this.setState({submitFormError: null});
            }, 5000);
          }
        }
      } else {
        
        this.setState({submitFormError: result.statusText});
        setTimeout(() => {
          this.setState({submitFormError: null});
        }, 5000);
      }
    } else {
      console.log('No Response');
    }
  }
  responseSuccess() {
    if (this.state.submitFormSuccess) {
      return <Alert color="success">
        {this.state.submitFormSuccess}
      </Alert>
    } else {
      return null;
    }
  }
  response(){
    if(this.state.submitFormError) {
      return <Alert color="danger">
        {this.state.submitFormError}
      </Alert>
    }
  }

  showLoading(){
    if(this.state.loading) {
      return <i className="fa fa-spinner fa-spin"></i>
    }
  }


  async getCountries() {
    let path = apiPath.getAllCountriesSignup;
    this.setState({ loading: true });
    let options = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData);
          this.setState({ countries: resultData.result });
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  componentDidMount() {
    this.getCountries();
  }

  countries() {
    if (!_.isEmpty(this.state.countries)) {
      return this.state.countries.map((make, index) => {
        return <option key={index} id={index} value={make._id}>{make.name}</option>
      })
    }
  }

  render() {
    if(!_.isEmpty(this.props.admin)) {
      return <Redirect to='/login' />
    }
    return (
      <div className="app flex-row align-items-center login-body">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  {this.response()}
                  {this.responseSuccess()}
                  <CardBody>
                    <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                    ref={this.formRef}
                                    immediate={this.state.immediate}
                                    setFocusOnError={this.state.setFocusOnError}
                                    defaultErrorMessage={{ required: "Please enter something."}} >
                      <h1>Sign Up</h1>
                      <p className="text-muted">Sign Up to your account</p>
                      <TextInput type={"hidden"} name="account_type" id="account_type" value={this.state.account_type} onChange={this.handleChange} placeholder={"Account Type"} required errorMessage="Please enter account type"/>
                      <InputGroup className="mb-3 countrySelect">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-flag"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <SelectGroup name="country" id="country" value={this.state.country} onChange={this.handleChange} required errorMessage="Please select country">
                            <option value="">Select Country</option>  
                            {this.countries()}
                        </SelectGroup>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <TextInput name="contact_name" id="contact_name" value={this.state.contact_name} onChange={this.handleChange} placeholder={"Contact Person Name"} required errorMessage="Please enter contact person name"/>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <TextInput name="name" id="name" value={this.state.name} onChange={this.handleChange} placeholder={"Restaurant Name"} required errorMessage="Please enter restaurant name"/>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-phone"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <TextInput name="phone" id="phone" value={this.state.phone} onChange={this.handleChange} placeholder={"Contact Number"} required 
                         validator={ (value)=> {if(validator.isLength(validator.trim(value), { min:8,max:15}) && validator.isNumeric(value)){return true}} }
                         errorMessage={{ required:" Please enter phone number",  validator: "Please enter a valid phone number" }}/>
                         <span style={{color : "red"}}>{(defLang == "/en/ru") ? "Пожалуйста, не добавляйте '+' в номер телефона" : ((defLang == "/en/tr") ? "Lütfen telefon numarasına '+' eklemeyin" : "Please do not add '+' in telephone number ")}</span>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-envelope"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <TextInput name="email" id="email" value={this.state.email} onChange={this.handleChange} 
                        placeholder={(defLang == "/en/ru") ? "Адрес электронной почты" : ((defLang == "/en/tr") ? "E-posta" : "Email Address")} required 
                        validator={validator.isEmail}
                        errorMessage={{ validator: "Please enter a valid email" }}/>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <TextInput type={this.state.passwordType} name="password" value={this.state.password} 
                                onChange={this.handleChange} 
                                validator={(value) => validator.isLength(validator.trim(value), { min:6,max:10})}
                                id="password" placeholder={"Password"} 
                                required errorMessage={{required : "please enter password.", validator: "Password must be 6 to 10 characters long."}}/>
                        <InputGroupAddon addonType="postpend"  onClick={this.handleButtonChange}>
                          <InputGroupText>
                            <i className={"fa "+this.state.faclass} style={{padding: "4px 0px 3px 0px"}}></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <TextInput type={this.state.passwordCType} name="confirm_password" value={this.state.confirm_password} 
                          onChange={this.handleChange}
                          id="confirm_password" 
                          placeholder={"Confirm Password"} 
                          validator={(value) => validator.equals(value, this.state.password)}
                          required errorMessage={{required : "please enter password.", validator: "Confirm password didn\'t match with the password."}}/>
                        <InputGroupAddon addonType="postpend"  onClick={this.handleCButtonChange}>
                          <InputGroupText>
                            <i className={"fa "+this.state.faCclass} style={{padding: "4px 0px 3px 0px"}}></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" type={"submit"} className="px-4">Signup {this.showLoading()}</Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          Already have Account? <Link to="/login">Login</Link>
                        </Col>
                      </Row>
                    </ValidationForm>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none forgotPP" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center login-right">
                    <div>
                    
                      <img src={logo} alt="Speedy Tum Tum"/>
                      <h2 className="notranslate">Speedy Tum Tum</h2>
                      <p>Restaurant Sign Up</p>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
