import React from "react";
import Zendesk from "react-zendesk";
const ZENDESK_KEY = "93132850-662e-45cc-bfac-5b774526c2a9";

// Take contact form as an example
// Let's customise our contact form appearance, launcher and add prefill content
const setting = {
  className:{
    position: "initial"
  },
  color: {
    theme: "#ff8417"
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help"
    }
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "My pre-filled description" } }
    ]
  }
};

const Support = () => {
  return <Zendesk zendeskKey={ZENDESK_KEY} {...setting} />
};

export default Support;
