// let appMode = 'stage';
let appMode = 'production';
/* if(window.location.origin === 'http://localhost:4300' || window.location.origin === 'http://192.168.1.248:4300') {
    appMode = 'stage';
} */
//let URL = (appMode === 'production') ? 'http://14.98.110.245:4304/' : 'http://192.168.1.102:4301/';
let URL = (appMode === 'production') ? 'https://rapi.speedytumtum.com/' : 'http://localhost:4302/';
let pathObj = {
    baseURL: URL,
    adminLogin: 'api/restaurants/login',
    forgotPassword: 'api/restaurants/forgot-password',
    changePassword: 'api/restaurants/change-password',
    signup: 'api/restaurants/signup',
    dashboardData: 'api/restaurants/dashboardData',
    getActiveDrivers: 'api/restaurants/getActiveDrivers',
    
    getProfile: 'api/restaurants/getProfile',
    updateProfile: 'api/restaurants/updateProfile',
    deleteRestaurantImage: 'api/restaurants/deleteRestaurantImage',
    updateRestaurantTime: 'api/restaurants/updateRestaurantTime',

    getAllCountries: 'api/restaurants/getAllCountries',
    getAllCountriesSignup: 'api/restaurants/getAllCountriesSignup',
    getCountryByID: 'api/restaurants/getCountryByID',
    getOrderList: 'api/restaurants/getOrderList',
    getOrderFoodItems: 'api/restaurants/getOrderFoodItems',
    updateDeliveryStatus: 'api/restaurants/updateDeliveryStatus',
    getTableReservations: 'api/restaurants/getTableReservations',
    updateTableReservation: 'api/restaurants/updateTableReservation',
    getDeliveryBoys: 'api/restaurants/getDeliveryBoys',
    addDeliveryBoy: 'api/restaurants/addDeliveryBoy',
    updateDeliveryBoy: 'api/restaurants/updateDeliveryBoy',
    getDeliveryBoyList: 'api/restaurants/getDeliveryBoyList',
    assignDeliveryBoy: 'api/restaurants/assignDeliveryBoy',
    orderInvoice: 'api/restaurants/orderInvoice',
    getComments: 'api/restaurants/getComments',
    deleteReviews: 'api/restaurants/deleteReviews',

    getAllCuisines: 'api/restaurants/getAllCuisines',
    getAllMaterials: 'api/restaurants/getAllMaterials',
    getAllExtraMaterials: 'api/restaurants/getAllExtraMaterials',
    getAllBeverages: 'api/restaurants/getAllBeverages',
    getAllSoftDrinks: 'api/restaurants/getAllSoftDrinks',

    getCuisines: 'api/restaurants/getCuisines',
    addCuisine: 'api/restaurants/addCuisine',
    updateCuisine: 'api/restaurants/updateCuisine',

    getNotifications: 'api/restaurants/getNotifications',
    
    getMaterials: 'api/restaurants/getMaterials',
    addMaterial: 'api/restaurants/addMaterial',
    updateMaterial: 'api/restaurants/updateMaterial',

    getRestaurantMenus: 'api/restaurants/getRestaurantMenus',
    addRestaurantMenu: 'api/restaurants/addRestaurantMenu',
    updateRestaurantMenu: 'api/restaurants/updateRestaurantMenu',

    getOffers: 'api/restaurants/getOffers',
    addOffer: 'api/restaurants/addOffer',
    updateOffer: 'api/restaurants/updateOffer',

    getAllZones: 'api/restaurants/getAllZones',
    getZonesDetailsByDboy: 'api/restaurants/getZonesDetailsByDboy',
    getEarnings: 'api/restaurants/getEarnings',
    getInvoices: 'api/restaurants/getInvoices',
    getCuisinesForRestaurant: 'api/restaurants/getCuisinesForRestaurant',

    getCustomer: 'api/restaurants/getCustomer',
    orderFood:'api/restaurants/orderFood',

    addUser:'api/restaurants/addUser'
}
export default pathObj;
