import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row} from 'reactstrap';
import { ValidationForm, TextInput} from "react-bootstrap4-form-validation";
import _ from "lodash";
import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Http from '../../../services/Http';
import apiPath from '../../../constants/apiPath';
import { adminLogin } from "../../../actions/index";
import helper from "../../../constants/helper";
import { connect } from "react-redux";

import logo from '../../../assets/img/brand/login_logo.png';
var defLang = "";
defLang = helper.getLanguageFromCookie('googtrans');

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class Login extends Component {
  constructor(props){
    super(props);
    // If you want to use the reset state function, you need to have a reference to the ValidationForm component
    //If your React < 16.3, check https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      immediate:true,
      faclass:"fa-eye",
      passwordType:"password",
      setFocusOnError:true,
      clearInputOnReset:false,
      account_type: 'restaurant',
      isRememberMe: (!_.isEmpty(localStorage.getItem('username')) && !_.isEmpty(localStorage.getItem('userPassword'))) ? true: false,
      username: localStorage.getItem('username') || '',
      password: localStorage.getItem('userPassword') || '',
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: (e.target.name === 'isRememberMe') ? e.target.checked : e.target.value
    });
    if((e.target.name === 'isRememberMe') && (e.target.checked === false)) {
      localStorage.removeItem('username');
      localStorage.removeItem('userPassword');
    }
  };

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.login(formData);
  };

  handleErrorSubmit = (e,formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  };

  handleButtonChange = (e) => {
    if(this.state.faclass == 'fa-eye'){
      this.setState({faclass : 'fa-eye-slash',passwordType : 'text'})
    }else{
      this.setState({faclass : 'fa-eye',passwordType : 'password'})
    }
  };

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  };

  async login(postData){
    let path = apiPath.adminLogin;

    if(!_.isEmpty(postData)) {
      postData.username = postData.username.toLowerCase();      
      this.setState({loading: true});
      let options = {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        body: JSON.stringify(postData)
      }
      let result = await Http(path, options);
      this.setState({loading: false});
      if(result.ok) {
        if(result.status === 200) {
          let resultData = await result.json();
          if(resultData.success) {
            let adminData = resultData.result;
            adminData.token = resultData.token;
            adminData.account_type = resultData.account_type;
            if(this.state.isRememberMe){

              localStorage.setItem('username', this.state.username);
              localStorage.setItem('userPassword', this.state.password);
            }
            this.props.adminLogin(adminData);
          } else {
            this.setState({submitFormError: resultData.msg});
            setTimeout(() => {
              this.setState({submitFormError: null});
            }, 5000);
          }
        }
      } else {
        
        this.setState({submitFormError: result.statusText});
        setTimeout(() => {
          this.setState({submitFormError: null});
        }, 5000);
      }
    } else {
      console.log('No Response');
    }
  }

  response(){
    if(this.state.submitFormError) {
      return <Alert color="danger">
        {this.state.submitFormError}
      </Alert>
    }
  }

  showLoading(){
    if(this.state.loading) {
      return <i className="fa fa-spinner fa-spin"></i>
    }
  }

  render() {

    if(!_.isEmpty(this.props.admin)) {
      return <Redirect to='/dashboard' />
    }
    /*if(window.location.origin && window.location.origin.indexOf('lsp.') > -1){
      return <Redirect to='/lsp-login' />
    }*/
    return (
      <div className="app flex-row align-items-center login-body">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  {this.response()}
                  <CardBody>
                    <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                    ref={this.formRef}
                                    immediate={this.state.immediate}
                                    setFocusOnError={this.state.setFocusOnError}
                                    defaultErrorMessage={{ required: "Please enter something."}} >
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <TextInput type={"hidden"} name="account_type" id="account_type" value={this.state.account_type} onChange={this.handleChange} placeholder={"Username"} required errorMessage="Please enter account type"/>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <TextInput name="username" id="username" value={this.state.username} onChange={this.handleChange} placeholder={"Username"} required errorMessage="Please enter username"/>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <TextInput type={this.state.passwordType} name="password" value={this.state.password} onChange={this.handleChange} id="password" placeholder={"Password"} required errorMessage="Please enter password"/>
                        <InputGroupAddon addonType="postpend"  onClick={this.handleButtonChange}>
                          <InputGroupText>
                            <i className={"fa "+this.state.faclass} style={{padding: "4px 0px 3px 0px"}}></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" name="isRememberMe" id="isRememberMe"
                                checked={this.state.isRememberMe}
                                onChange={this.handleChange}
                              />{' '}
                                Remember Me
                            </Label>
                          </FormGroup><br />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" type={"submit"} className="px-4">Login {this.showLoading()}</Button>
                        </Col>
                        <Col xs="6">
                          <Link to="/forgot-password"><span className="notranslate">{(defLang == "/en/ru") ? "Забыл пароль" : ((defLang == "/en/tr") ? "Şifremi unuttum" : "Forgot password")} </span>?</Link>
                        </Col>
                      </Row>

                    </ValidationForm>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none forgotPP" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center login-right">
                    <div>
                      
                      <img src={logo} alt="Speedy Tum Tum"/>
                      <h2 className="notranslate">Speedy Tum Tum</h2>
                      <p>Restaurant Login</p>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
