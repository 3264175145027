import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import connect from "react-redux/es/connect/connect";
// import flag_1 from '../../assets/img/language-1.png';
// import flag_2 from '../../assets/img/language-2.png';
// import flag_3 from '../../assets/img/language-3.png';
// import flag_4 from '../../assets/img/language-4.png';
import massages from '../../assets/img/massages.png';

import _ from "lodash";
import {
  AppAside,
  AppBreadcrumb,
  // AppFooter,
  // AppHeader,
  // AppSidebar,
  // AppSidebarFooter,
  // AppSidebarForm,
  // AppSidebarHeader,
  // AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
import lsp_navigation from '../../lsp_nav';
// routes config
import routes from '../../routes';
import lsp_routes from '../../lsp_routes';

import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import NotificationSound from "../../assets/notification_sounds/notification_whip.mp3";

import { toast } from 'react-toastify';

import { adminLogin } from "../../actions/index";

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    userLogin: admin => dispatch(adminLogin(admin))
  };
};

class DefaultLayout extends Component {

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.audioPlayer = React.createRef();
  }


  render() {
    // var ws = new WebSocket("ws://168.119.55.59:8000/");

    // // websocket onopen event listener
    // ws.onopen = () => {
    //   console.log("connected awaiting messages...");
    // };

    // ws.onmessage = (message) => {
    //   var order = JSON.parse(message.data);
    //   var user = JSON.parse(localStorage.getItem('admin'));
    //   console.log({
    //     'order': order,
    //     'localStorage': user.admin._id
    //   });
    //   if (order.restaurant_id == user.admin._id) {
    //     this.audioPlayer.current.play();
    //     toast.dismiss();
    //     toast.info('New order placed!', {
    //       position: toast.POSITION.TOP_RIGHT
    //   });
    //   }
    // };

    if (_.isEmpty(this.props.admin)) {
      return <Redirect from="/" to={(localStorage.getItem("redirect") ? localStorage.getItem("redirect") : "/login")} />
    }


    // function ShowAppNav(props) {
    //   var showBreads = (props.account_type === 'lsp') ? lsp_navigation: navigation;
    //   return (
    //     <AppSidebarNav navConfig={showBreads} {...props} />
    //   );
    // }

    // function ShowBreadcrumb(props) {
    //   var showBreads = (props.account_type === 'lsp') ? lsp_routes: routes;
    //   return (
    //     <AppBreadcrumb appRoutes={showBreads}/>
    //   );
    // }

    function AppRoutes(props) {
      var AccountRoutes = (props.account_type === 'lsp') ? lsp_routes : routes;
      return (
        <Switch>
          {AccountRoutes.map((route, idx) => {
            return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
              <route.component {...props} />
            )} />)
              : (null);
          },
          )}
          <Redirect from="/" to="/dashboard" />
        </Switch>
      );
    }

    return (
      <div className="app">
        <section className="top-header">
          <div className="container">
            <div className="row">
              <div className="col-md-12">

                <div className="select-right">


                  <div className="massages-count" style={{ visibility: 'hidden' }}>
                    <a href="#"><img src={massages} alt="" /></a>
                    <span>2</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <header>
          <DefaultHeader />
        </header>
        <div className="app-body">
          <main className="main">
            <Container fluid>
              <AppRoutes account_type={this.props.admin.account_type} />
            </Container>
          </main>
          <AppAside fixed>
            <DefaultAside />
          </AppAside>
        </div>
        <footer>
          <DefaultFooter />
        </footer>
        <audio ref={this.audioPlayer} src={NotificationSound} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
